import React from "react";

const TOC = ({ openFAQ, isVisible = false }) => {
  return (
    <section className="toc" aria-hidden={isVisible}>
      <div className="toc-wrapper">
        <button className="toc-close" onClick={() => openFAQ()}>
          close modal
        </button>
        <h1>Blackhawks NFT FAQ</h1>
        <div className="toc-content">
          <ol>
            <li>
              <h3>What are digital collectibles?</h3>
              <p>
                Sweet has developed technology which can be used by artists,
                teams and brands to create unique, one-of-a-kind digital
                collectibles for fans and consumers. Digital collectibles can
                take on any form: a piece of art, a unique pin, token card or
                image, an embedded video, audio or animation.
              </p>
              <p>
                A digital collectible is similar to a physical collectible, but
                in digital format. Like a physical collectible, a digital
                collectible is distinguished and unique. To ensure each digital
                collectible’s unique authenticity, Sweet uses blockchain
                technology to demonstrate rights ownership and authenticity.
                This is similar to receiving a "certificate of authenticity"
                with unique physical merchandise like a signed baseball card.
              </p>
            </li>
            <li>
              <h3>
                What makes digital collectibles different from physical
                collectibles?
              </h3>
              <p>
                Physical and digital collectibles have many similar traits, such
                as strong fan affinity and pride of ownership (otherwise known
                as “bragging rights” as you show off your collections). However,
                digital collectibles have many advantages over physical
                collectibles. This helps to explain the transformation of the
                collectible space over time and the rapid growth of digital
                collectibles.
              </p>
              <p>
                The advantages that digital collectibles have over physical
                collectibles include:
              </p>
              <p>
                <ul>
                  <li>
                    Prevention of Counterfeits - A digital collectible’s
                    authenticity is more reliable and easier to manage. It can
                    be tied to a Non-fungible Token (NFT), which is the digital
                    equivalent of a certificate of authenticity, and solves a
                    lot of issues around fraud that can occur in the physical
                    world.
                  </li>
                  <li>
                    Better Storage and Shipping - A digital collectible is
                    easier to store and ship without wear and tear that can
                    occur with physical collectibles.
                  </li>
                  <li>
                    Better Sharing - A digital collectible has vastly better
                    sharing options if you want to show your collectible or
                    collection to friends, family or the public. You can do so
                    over text, email or a public showcase. You can also present
                    your digital collectible online or in an offline frame that
                    has storage and display capability.
                  </li>
                </ul>
              </p>
              <p>
                A fan or user can have the same thrill, affinity and enjoyment
                from owning a digital collectible as owning a physical one, plus
                all the conveniences of better authenticity, shipping, storage,
                and sharing.
              </p>
            </li>
            <li>
              <h3>
                Tell me more. What are other benefits of my digital
                collectibles?
              </h3>
              <p>
                In addition to owning your digital collectibles and displaying
                and sharing them as you want (enjoy the bragging rights!) there
                are lots of other benefits. Some artists, teams, and brands
                offer personalized benefits, such as VIP access to a concert or
                event, or access to unique content. Have fun and be creative and
                in some cases you may be able to add on-to, customize, or
                enhance your digital collectible. But you should note that any
                such changes will be layered enhancements, and will not change
                the underlying NFT.
              </p>
            </li>
            <li>
              <h3>Are my collectibles unique?</h3>
              <p>
                Every digital collectible from Sweet or any of Sweet partners or
                clients is unique, individually-numbered, and trackable on the
                public blockchain with records of authenticity. It might be
                unique with only one created, or it might be individually
                numbered and part of a limited series. Sweet creates each
                collectible series on the public blockchain, including the total
                number available. Once created, the series cannot be edited or
                changed by any party, including Sweet, its partners, or clients.
                The public blockchain also stores a permanent record of who
                created it, and every owner.
              </p>
            </li>
            <li>
              <h3>How can I display my digital collectibles?</h3>
              <p>
                You can choose how to display your digital collectibles for your
                own use and enjoyment and how you want to share them for others
                to view and enjoy. You can share just one collectible or your
                full collection. Users can optionally add narrative and other
                data to each digital collectible, explaining how they obtained
                the digital collectible and why it’s special to them.
              </p>
            </li>
            <li>
              <h3>Can I trade or sell my digital collectible?</h3>
              <p>
                Digital collectibles by their very nature are intended to be
                used, displayed and enjoyed. You own the digital collectible and
                if you want to give it away, gift it, or sell it for whatever
                reason you can. Exchanging digital collectibles is a great way
                to engage with others who have the same affinity for the talents
                and brands that you do.
              </p>
              <p>
                To trade a collectible that you own, open the details view of
                your collectible and select the “Trade on Sweet” button. From
                there you’ll be able to request a trade with other users.
                Alternatively, you can trade for a sold-out collectible by
                visiting the collectibles “Store” (far left tab) and choosing
                “Collect this Coin/Card.” Each trade request remains open for 24
                hours and will expire if no owners accept the trade. You can
                also gift or send your collectible to a friend, family member,
                or someone else, and they can receive it using the Sweet mobile
                application.
              </p>
              <p>
                Sweet will be providing further details here on how to sell your
                digital collectibles.
              </p>
            </li>
            <li>
              <h3>What is the value of my digital collectible?</h3>
              <p>
                A digital collectible’s value is personal, based on your
                connection with the item you are collecting and how it fits with
                all your other collectibles both digital and physical. One
                person might love to have a digital collectible from a specific
                artist, talent, place or team and have a real affinity, while
                someone else might not have the same affinity and see no reason
                to get it, just like with collectibles in the physical world!
              </p>
              <p>
                You should collect, purchase or trade for a digital collectible
                for your own personal use and enjoyment. You should not purchase
                a digital collectible with the expectation of making any profit
                from its resale as there is no guarantee that you will be able
                to find a buyer for it or that, even if you are able to find a
                buyer for it, you will be able to get the same or higher value
                which you paid for it. Neither Sweet nor any of the talent or
                brands represented by the digital collectible are undertaking
                any obligation to support or encourage any price appreciation of
                any digital collectible.
              </p>
              <p>
                There are many factors that influence what someone will trade or
                purchase your digital collectible for, and these are outside of
                the control of Sweet or any of Sweet’s partners or clients
                represented on the digital collectible. Examples of factors that
                influence a buyer’s decision include a potential buyer’s own
                personal connection or affinity with your unique digital
                collectible, the general interest in the talent or brand
                associated with the digital collectible at any given time, the
                general interest in collectibles overall (both physical and
                digital), and many other factors.
              </p>
            </li>
          </ol>
          <h2>NFTs/Blockchain Technology</h2>
          <ol>
            <li>
              <h3>
                I’ve heard of NFTs. What are they and how are they similar to or
                different from Bitcoin?
              </h3>
              <p>
                A Non-fungible Token (NFT) is a type of cryptographic token
                which represents something unique. Non-fungible tokens are not
                mutually interchangeable and can’t be divided into smaller
                parts. NFTs can therefore be used as unique digital
                collectibles. Each NFT has its own unique "immutable data," such
                as total inventory, artwork, audio files, and other information
                which can never be changed. In some cases, you may be able to
                add to, customize, or enhance your digital collectible, but any
                such changes will be additional enhancements and not change the
                underlying NFT or digital collectible.
              </p>
              <p>
                By contrast a Fungible Token like Bitcoin is very different, in
                that it is divisible into any number of smaller units and it
                does not matter which unit you own. Each unit is uniform and
                interchangeable with any other like amount of Bitcoin. This
                makes fungible tokens useful as a currency.
              </p>
            </li>
            <li>
              <h3>What blockchain does Sweet use?</h3>
              <p>
                Sweet’s platform enables brands to create NFT collectibles on
                multiple NFT compatible chains. We currently support the
                Ethereum ERC721 standard on Polygon / Matic and Ethereum
                Mainnet, the SLP standard for NFTs, the Tezos standard for NFTs,
                and will be adding additional NFT compatible blockchains as the
                digital collectibles marketplace evolves. All of our NFT
                implementations, regardless of the underlying blockchain
                standard, will support the similar features and user experience,
                and provide the same benefits of a blockchain-based digital
                collectible. There are however differences in economics between
                standards. Some of the blockchains we support for example
                have a carbon footprint that is roughly 500 times smaller than
                Ethereum Mainnet based collectibles, offering a green option for
                brands that wish to publish a large number of collectibles.
              </p>
            </li>
            <li>
              <h3>
                Is the Sweet Web Marketplace and Mobile App a blockchain wallet?
              </h3>
              <p>
                Yes. Among other things, both the marketplace at Sweet.io and
                the Sweet mobile app serve as a blockchain wallet for a user to
                manage their digital collectibles. The sweet app and wallet are
                however specifically designed for digital collectibles. Sweet is
                not a blockchain wallet that is available for general blockchain
                tokens like Bitcoin and should not be confused with companies
                like Coinbase or wallets like blockchain.com that hold all types
                of crypto blockchain tokens like Bitcoin.
              </p>
              <p>
                The center “Wallet” tab in the Sweet app displays all of the
                collectibles you own. You can also access your wallet on the
                Sweet.io web marketplace. From either location, you can also
                view your collectibles from a public blockchain explorer
                website. Clicking on the “Authenticity” link below any owned
                collectible will display the public blockchain open ledger view
                of your collectible, so you can be sure you have the real thing!
                The Sweet app can support NFTs from multiple NFT compatible
                blockchains.
              </p>
            </li>
            <li>
              <h3>
                How are digital assets like images or videos associated with an
                NFT?
              </h3>
              <p>
                A digital collectible will have one or more assets associated
                with it, such as an MP4, or a JPEG image. We cryptographically
                sign each of these assets, and commit a signature of the digital
                collectible to the blockchain when we create it. This allows the
                holder of an NFT to prove that a particular image or media file
                belongs to the NFT. Many NFT publishers only use URLs to define
                the digital collectible, and this prevents NFT holders from
                proving whether a media file is associated with the NFT. We have
                solved this problem by incorporating cryptographic hashes during
                collectible creation.
              </p>
            </li>
            <li>
              <h3>
                What rights do NFT owners have for the digital collectible?
              </h3>
              <p>
                Each Digital Collectible definition has a link to terms and
                conditions, which determine exactly what rights you have to
                copy, transfer, display, or otherwise use the various media
                files that compose the digital collectible. Many NFT publishers
                don’t take this step and it is unclear what rights an NFT
                confers on a user if the publisher has not also committed terms
                and conditions to the blockchain.
              </p>
            </li>
            <li>
              <h3>
                Does the holder of a Digital Collectible “own" the artwork or
                media files?
              </h3>
              <p>
                No. Along with rights information, we also include a copyright
                notice with our NFTs, and this information along with the terms
                and conditions is verifiable through the blockchain record.
                Sweet or our Clients retain the copyright, but the NFT owner is
                granted certain rights per our Terms and Conditions.
              </p>
            </li>
            <li>
              <h3>
                Who is hosting the digital collectible, and what happens 100
                years from now if Sweet is not around?
              </h3>
              <p>
                We currently host the digital collectibles publicly on an Amazon
                Web Services Content Delivery Network (CDN). However, this is
                not the only way they can be hosted, and the NFT definition and
                chain of authenticity is designed to survive Sweet. The NFT
                document describes all of the digital assets, and the terms of
                their use. The document also provides a cryptographic hash that
                an NFT owner can use to prove a particular set of digital assets
                is associated with their NFT. Because of the chain of hashes
                used to associate content with an NFT, the existence of Sweet’s
                CDN is not necessary, and copies of the collectibles index can
                be made that still allow proof of ownership via the blockchain.
                This allows the Digital Collectibles to survive independent of
                Sweet or the Amazon CDN. Our Terms and Conditions are designed
                to cover this scenario, and ensure that the person in possession
                of the NFT can continue to make use of it.
              </p>
            </li>
          </ol>

          <h2>Using the Sweet Marketplace and Mobile App</h2>
          <ol>
            <li>
              <h3>What do digital collectables look like?</h3>
              <p>
                Some digital collectibles are cards and coins which you might
                see in the physical collectibles market, but digital
                collectibles can go so much further and provide even richer
                experiences. A digital collectible can take any form and is only
                limited by the creativity of its creator. Unique collectables
                (or Non-fungible Tokens) offered by Sweet and our partners and
                clients can have embedded images, audio or video and other
                animations. We partner with artists and consumer brands to make
                truly one-of-a-kind collectibles for your enjoyment.
              </p>
            </li>
            <li>
              <h3>How do I complete a collectible set?</h3>
              <p>
                To complete a collectible set you must collect all the cards and
                coins in the set. You can do that by purchasing the collectibles
                in the store or reserving them in online “drops”! To see which
                cards or coins are part of a set, you can visit the “Sets” tab
                (second from the left).
              </p>
              <p>
                There are benefits to completing digital collectible sets, just
                like with physical collectibles. You can show off your set to
                your friends as a superfan and Sweet along with some artists and
                brands offer some fun rewards to set owners.
              </p>
            </li>
            <li>
              <h3>How do I access my digital collectibles collection?</h3>
              <p>
                To access your digital collectibles collection, login to your
                wallet on the web at Sweet.io or use the Sweet mobile App and
                access the “Wallet” (the center tab on the bottom). From there
                you can view all your digital collectibles.
              </p>
            </li>
            <li>
              <h3>
                Can I access my collectibles outside of Sweet? How do I transfer
                collectibles outside of the Sweet App to my own blockchain
                wallet?
              </h3>
              <p>
                Please follow instructions in the Sweet app to submit your
                information to be eligible to transfer collectibles outside of
                the Sweet App to your own blockchain wallet. Please note, Sweet
                partners may restrict transfers of some collectibles.
              </p>
            </li>
            <li>
              <h3>How do I earn more digital collectibles?</h3>
              <p>
                You can earn digital collectibles like cards and coins in a few
                ways. In the collectibles store, some cards and coins can be
                redeemed by listening to a song by the artist, while others can
                be purchased outright. You can also trade for collectibles
                within the Sweet app!
              </p>
            </li>
            <li>
              <h3>What are the Sweet rarity categories?</h3>
              <p>
                Collectibles are sorted into “tiers” which reflect their level
                of rarity.
              </p>
              <p>
                <ul>
                  <li>
                    Tier 1: Higher availability unique digital collectibles
                  </li>
                  <li>
                    Tier 2: Slightly less common unique digital collectibles
                  </li>
                  <li>
                    Tier 3: Harder to acquire one-of-a-kind digital collectibles
                  </li>
                  <li>Tier 4: Rare one-of-a-kind digital collectibles</li>
                  <li>Tier 5: Uber rare one-of-a-kind digital collectibles</li>
                </ul>
              </p>
            </li>
            <li>
              <h3>Are artists involved in their collections?</h3>
              <p>
                Yes, of course. Artists decide what their digital collectible
                will be, and each digital collectible is unique and very much
                represents the brand, team or artist.
              </p>
            </li>
            <li>
              <h3>
                I reserved a card/coin from an artist online, but I don’t see it
                in my Sweet account. Why is this happening?
              </h3>
              <p>
                You can reserve a special edition digital collectible like a
                coin or card directly from an artist by entering your email
                address or phone number online. If you don’t have a Sweet
                account, you can register for an account using that same phone
                number or email and you will find your reserved collectible in
                your wallet. If you do have a Sweet account attached to that
                phone number or email, all you have to do is login to see that
                collectible in your wallet!
              </p>
              <p>
                If you have a Sweet account, but used a mobile number or email
                not associated with your account, you can edit your mobile
                number or email in your profile, and the collectible will be
                added to your wallet within 24 hours.
              </p>
            </li>
            <li>
              <h3>
                Does my digital collectible give me any ownership rights in
                Sweet or any other entity?
              </h3>
              <p>
                No, owning a digital collectible does not give you any rights to
                equity or other ownership in Sweet or any Sweet-affiliated
                clients or partners.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default TOC;
