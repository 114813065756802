import React, { useState } from "react";

// components
import Header from "components/Header";
import Footer from "components/Footer";
import SEO from "components/SEO";
import Wave from "components/Wave";
import Countdown from "components/Countdown";
import Subscribe from "components/Subscribe";
import FAQ from "components/FAQ";

// assets
import "normalize.css";
import "src/styles/main.scss";

// data
import data from "content/data.json";

if (typeof window !== `undefined`) {
  window.items = data.waves[0].items;
  window.SWT_WEB_LAND = true;
}

const howTo = data["howTo"];
const verification = data["verification"];

const isBrowser = typeof window !== "undefined";

const IndexPage = () => {
	const marketplaceUrl = "https://sweet.io/blackhawks";

  const html = isBrowser ? document.querySelector("html") : null;
  const [isFAQModalVisible, setIsFAQModalVisible] = useState(false);

  const toggleFAQModal = () => {
    setIsFAQModalVisible(!isFAQModalVisible);
    if (html) {
      if (isFAQModalVisible == true) {
        html.classList.remove("noscroll");
      } else {
        html.classList.add("noscroll");
      }
    }
  };
  const [isDropActive, setIsDropActive] = useState(false);
  const activateDrop = () => setIsDropActive(true);

  return (
    <main className="container">
      <SEO />
      <Header title={data.dropTitle} />
      <section className="content">
        <div className="intro">
          <h2 className="intro__title">Chicago Blackhawks</h2>
          <h3 className="intro__banner">
            <a href={marketplaceUrl}>Inaugural NFT Collection</a>
            <span>8-bit!</span>
          </h3>
          <p className="intro__text">Be a part of Blackhawks history by getting your first team-issued NFT – choose from 12 animated characters, 48 static characters and 2,000 digital tickets!</p>
        </div>
        <Countdown date={data.dropDate} onCountdownEnd={activateDrop} />
        <div className="blk__slider-title">
          <a href={marketplaceUrl}>
            Collect your character
          </a>
        </div>
        <div className="blk__slider">
          {([...Array(13).keys()]).map(i => {
            return (
              i > 0 && (
                <span className={"blk__column blk__column--" + i} key={i}></span>
              )
            )
          })}
        </div>
        <div className="blk__intro">
          <h1>2 teams in 8-bit gaming glory</h1>
          <h2>Check out the roster and collect your favorite players</h2>
          <p>12 hockey characters, each with a unique name, description and set of attributes inspired by Blackhawks history, the city of Chicago, the United Center and the great game of hockey are available. Six characters adorn the Blackhawks home red jerseys while the other six wear the team’s road whites to complete a full on-ice lineup of 10 skaters and two goalies. You’re going to want one of these for your collection so look over the roster and choose your favorite characters!</p>
          <a className="button" href={marketplaceUrl}>
            Bid On Your Character
          </a>
        </div>
        <div className="blk__items">
          {data.waves.map((wave, index) => {
            return (
              wave.isAvailable && (
                <div className="wave__wrapper" key={index}>
                  <Wave 
                    key={index} 
                    wave={wave}
                    isDropActive={isDropActive}
                    siteUrl={marketplaceUrl}
                    hasModel3d={false} />
                </div>
              )
            );
          })}
          {data.subscribe['isAvailable'] && <Subscribe mailchimpId={data.subscribe['mailchimpId']} />}
          {
            howTo['isAvailable'] && (
              <div className="auctions">
                <h2 className="auctions-title">{howTo["title"]}</h2>
                <h3 className="auctions-subtitle">{howTo["subTitle"]}</h3>
                <p className="auctions-content">{howTo["text"]}</p>
              </div>
            )
          }
          {
            verification['isAvailable'] && (
              <div className="verification">
                <h2 className="verification-title">{verification["title"]}</h2>
                <p className="verification-content">{verification["text"]}</p>
                <div className="verification-shadow" />
              </div>
            )
          }
          {data.faq['isAvailable'] && <a className="button toc-open" onClick={() => toggleFAQModal()}>Read our FAQs</a>}
        </div>
      </section>
      {isFAQModalVisible && (
        <FAQ openFAQ={() => toggleFAQModal()} isVisible={isFAQModalVisible} />
      )}
      <Footer />
    </main>
  );
};

export default IndexPage;
