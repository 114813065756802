let url = 'https://sweet.io';

const returnUrl = page => {
  return(url + page);
}

export const pageSections = {
  START: {
    label: 'Sweet!',
    url: returnUrl('#start'),
  },
  CONTACT_US: {
    label: 'Contact us',
		url: returnUrl('#contact-us'),
	}
};

export const socialMedia = [
  {
    name: 'facebook',
    label: 'Facebook',
    url: 'https://www.facebook.com/socialsweet/'
  },
  {
    name: 'twitter',
    label: 'Twitter',
    url: 'https://twitter.com/sweet'
  },
  {
    name: 'instagram',
    label: 'Instagram',
    url: 'https://www.instagram.com/sweet'
  },
  {
    name: 'linkedin',
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/company/sweet.io/'
  }
]

export const appLinks = [
  {
    name: 'app-store',
    label: 'App Store',
    url: 'https://apps.apple.com/us/app/sweet/id1452120535'
  },
  {
    name: 'google-play',
    label: 'Google Play Store',
    url: 'https://play.google.com/store/apps/details?id=io.sweet.app&hl=en_US'
  }
]

export const legalPages = [
  {
    name: 'privacy',
    label: 'Privacy',
    url: returnUrl('/legal/privacy')
  },
  {
    name: 'terms-conditions',
    label: 'Terms of Use',
    url: returnUrl('/legal/terms')
  },
  {
    name: 'faq',
    label: 'FAQ',
    url: returnUrl('/faq')
  }
]

export const contactFormAction = "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"