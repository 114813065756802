import React from "react";
import { pageSections, socialMedia, appLinks, legalPages } from "libs/routes";

import launchIntercomApp from 'components/IntercomChat';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__logo">
          <a 
            href="https://sweet.io"
            target="_blank"
            rel="noreferrer">Sweet</a>
          <small>&copy; {new Date().getFullYear()} Sweet</small>
        </div>
        <div className="footer__nav">
          <nav className="footer__section footer__section-about">
            <h3 className="footer__section-title">About Sweet</h3>
            {Object.values(pageSections).map((item, index) => {
              return (
                !item.isLabelHidden && (
                  <a
                    className="footer__link"
                    href={item.url}
                    key={index}
                    target="_blank"
                    rel="noreferrer">
                    {item.label}
                  </a>
                )
              );
            })}
          </nav>
          <nav className="footer__section footer__section-info">
            <h3 className="footer__section-title">More Info</h3>
            {Object.values(legalPages).map((item, index) => {
              return (
                <a
                  href={item.url}
                  className="footer__link"
                  target="_blank"
                  key={item.name}>
                  {item.label}
                </a>
              );
            })}
          </nav>
          <nav className="footer__section footer__section-contact">
            <h3 className="footer__section-title">Contact Us</h3>
            <button
              as="a"
              className="footer__link"
              onClick={() => launchIntercomApp()}>
              Customer Support
            </button>
            <a
              href="mailto:press@sweet.io"
              rel="noreferrer"
              target="_blank"
              className="footer__link">
              press@sweet.io
            </a>
          </nav>
          <nav className="footer__section footer__section-apps">
            <h3 className="footer__section-title">Get the app</h3>
            <div className="footer__apps">
              {appLinks.map((item) => {
                return (
                  <a
                    className={"app-button app-button--" + item.name}
                    rel="noreferrer"
                    target="_blank"
                    alt={item.label}
                    href={item.url}
                    key={item.name}>
                    {item.label}
                  </a>
                );
              })}
            </div>
          </nav>
          <nav className="footer__section footer__section-social">
            <h3 className="footer__section-title">Follow Us</h3>
            <div className="footer__social-grid">
              {socialMedia.map((item, index) => {
                return (
                  <a
                    href={item.url}
                    className={
                      "footer__social-item footer__social-" + item.name
                    }
                    rel="noreferrer"
                    key={index}
                    alt={"Sweet @ " + item.label}
                    target="_blank">
                    {item.label}
                  </a>
                );
              })}
            </div>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
