import React from "react";
import { useScript } from "libs/useScript";

import Item from "components/Item";

const Wave = (props) => {
  const { title, subTitle, hasModel3d, isFeatured, intro, items } = props.wave;
  let isVisualizerReady;

  if (hasModel3d) {
    if (typeof window !== `undefined`) {
        const localScript = document.createElement("script");
        const scriptText = document.createTextNode(`
          window.SWT_WEB_LAND = true;
        `);
        localScript.appendChild(scriptText);
        document.head.appendChild(localScript);
      }

    isVisualizerReady = useScript('https://collectible.playground.sweet.io/static/visualizer.js');
  }

  return (
    <div
      className="wave"
      data-isactive={props.isDropActive}
      data-isfeatured={isFeatured}>
      <h2 className="wave__title">
        <a href={props.siteUrl}>{title}</a>
      </h2>
      <ul className="wave__items">
        {items.map((item, index) => {
          return (
            hasModel3d ? (
              (isVisualizerReady === 'ready') && <Item key={index} index={index} data={item} isActive={props.isDropActive} isFeatured={isFeatured} />
              ) : (
                <Item key={index} index={index} data={item} isActive={props.isDropActive} isFeatured={isFeatured} />
                )
                )
              })}
      </ul>
      <div className="wave__desc">
        <h3 className="wave__subtitle">4 tickets to join in on the action</h3>
        <h3 className="wave__intro">Select your favorite or collect all 4 to sweep the series and unlock a bonus ‘Golden Ticket’</h3>
        <p className="wave__text">
          Add 8-bit collectible tickets to your collection. 2,000 total NFTs are available (500 of each ticket) on a first-come, first-serve basis. Purchasers who buy all four editions and sweep the series will unlock a bonus ‘Golden Ticket’.
        </p>
        <a className="button" href="http://sweet.io/blackhawks">
          Buy your tickets now
        </a>
      </div>
  </div>
  );
};

export default Wave;
