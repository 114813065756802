import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				title
				description
				url
        image
        intercomId
			}
		}
	}
`;

const SEO = ({ noFollow = false }) => {
  const { site } = useStaticQuery(query);
	const { title, description, url, image, intercomId } = site.siteMetadata;

  return (
    <Helmet title={title}>
      <meta name="description" content={description} />
      <meta name="image" content={url + "/" + image} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={url + "/" + image} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={url + "/" + image} />
			{noFollow && <meta name="robots" content="noindex, nofollow" />}
			<script>
				{`
      (function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;const i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${intercomId}' ;const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
			</script>
    </Helmet>
  );
};

export default SEO;
