import React, { useEffect } from "react";

import { isDesktop } from "react-device-detect";
import { itemTypes } from 'libs/itemTypes';

const Item = (props) => {
  const {
    id,
    title,
    subTitle,
    intro,
    type,
    staticImage,
    url,
    isAvailable,
    ctaLabel,
    totalNumber,
    sequenceNumber,
    modelInfo
  } = props.data;

  // let ctaButton = isAvailable ? ctaLabel : "Coming soon";

  const CtaButton = () => {
    let button = (
      <span className="button item__cta" data-isavailable={isAvailable}>
        Coming soon
      </span>
    )

    if (isAvailable) {
      button = (
        <a className="button item__cta" href={url}>
          {ctaLabel}
        </a>
      )
    }
    return button;
  }

  let args;
  let loadTimeout;

  if (type == itemTypes.MODEL_3d) {
    args = {
      image: staticImage,
      reverse_image: staticImage,
      type: "model3d",
      pic_type: "animated",
      speed: "slow",
      sequence_number: sequenceNumber,
      total_number: totalNumber,
      name: title,
      is_web_landing: true,
      height: modelInfo['width'],
      width: modelInfo['height'],
      color: "#74B8F9",
      metalness: "0.5",
      roughness: "0.5",
      model3d: modelInfo['model3d'],
      autorotate: true,
      autoresize_canvas: false,
      custom_configs: modelInfo['customConfigs'],
    };

    loadTimeout = (props.index + 1) * 1500;
  }

  useEffect(() => {
    if (isDesktop && type == itemTypes.MODEL_3d) {
      if (window.SWT_VISUALIZER) {
        setTimeout(function () {
          const SWT_VISUALIZER_INSTANCE = new window.SWT_VISUALIZER(args);
          var canvas = document.getElementById(id);
          if (canvas) {
            SWT_VISUALIZER_INSTANCE.draw(canvas);
            if (
              window.matchMedia("(max-device-width: 960px)").matches &&
              args.iscard
            ) {
              setTimeout(() => {
                SWT_VISUALIZER_INSTANCE._sceneLoader._camera.position.z = 5.25;
                SWT_VISUALIZER_INSTANCE._sceneLoader._camera.position.y = 0.5;
              }, 500);
            }
          }
        }, loadTimeout);
      }
    }
  }, []);

  return (
    <li className="item" data-isavailable={isAvailable}>
      <div
        className="item__image"
        data-hasimg={(type == itemTypes.MODEL_3d && modelInfo['customConfigs']) || staticImage ? true : false}>
        {(isDesktop && type == itemTypes.MODEL_3d) ? (
          <>
            <canvas id={id} />
            <div className="item__loading" id="loading">
              Loading
            </div>
          </>
        ) : (
          <img src={staticImage} className="item__staticImage" />
        )}
      </div>
      <span className="item__shadow" />
    </li>
  );
};

export default Item;
