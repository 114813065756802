import React from "react";

const Header = (props) => {
  const { title, intro } = props;

  return (
    <header className="header">
      <div className="header__banner">
        <span className="header__logo">{title}</span>
      </div>
    </header>
  );
};

export default Header;
