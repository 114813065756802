const intercomAppId = 'to266qtx';

const launchIntercomApp = () => {
	window.Intercom('boot', {
		app_id: intercomAppId,
  });
  window.Intercom('show');
};

export default launchIntercomApp;
